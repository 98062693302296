var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form-wizard', {
    ref: "form",
    staticClass: "checkout-form-wizard steps-transparent",
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "finish-button-text": "Simpan",
      "back-button-text": "Kembali",
      "hide-buttons": ""
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Informasi Penjualan",
      "icon": "feather icon-file-text"
    }
  }, [_c('penjualan-info', {
    attrs: {
      "ajuanList": _vm.ajuanList
    },
    on: {
      "next-step": _vm.saveInfo
    },
    scopedSlots: _vm._u([{
      key: "content-selected-barang",
      fn: function (_ref) {
        var barangs = _ref.barangs,
          doFormatRupiah = _ref.doFormatRupiah,
          remove = _ref.remove;
        return [_c('b-table', {
          attrs: {
            "responsive": "",
            "bordered": "",
            "striped": "",
            "hover": "",
            "fields": _vm.fields,
            "items": barangs
          },
          scopedSlots: _vm._u([{
            key: "table-colgroup",
            fn: function (_ref2) {
              var fields = _ref2.fields;
              return _vm._l(fields, function (field) {
                return _c('col', {
                  key: field.key,
                  style: {
                    width: field.key == 'qty' ? '140px' : field.key == 'diskon' ? '160px' : ''
                  }
                });
              });
            }
          }, {
            key: "cell(satuan)",
            fn: function (_ref3) {
              var item = _ref3.item;
              return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '-') + " ")];
            }
          }, {
            key: "cell(stocks)",
            fn: function (_ref4) {
              var item = _ref4.item;
              return [_vm._v(" " + _vm._s(item.stocks.length > 0 ? item.stocks.reduce(function (total, item) {
                return total += item.jumlah;
              }, 0) : 0) + " ")];
            }
          }, {
            key: "cell(qty)",
            fn: function (_ref5) {
              var item = _ref5.item;
              return [_c('b-form-input', {
                attrs: {
                  "type": "number"
                },
                model: {
                  value: item.qty,
                  callback: function ($$v) {
                    _vm.$set(item, "qty", $$v);
                  },
                  expression: "item.qty"
                }
              })];
            }
          }, {
            key: "cell(harga)",
            fn: function (_ref6) {
              var item = _ref6.item;
              return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga)) + " ")];
            }
          }, {
            key: "cell(diskon)",
            fn: function (_ref7) {
              var item = _ref7.item,
                index = _ref7.index;
              return [_c('b-form-input', {
                on: {
                  "keyup": function ($event) {
                    return doFormatRupiah(index);
                  }
                },
                model: {
                  value: item.diskon,
                  callback: function ($$v) {
                    _vm.$set(item, "diskon", $$v);
                  },
                  expression: "item.diskon"
                }
              })];
            }
          }, {
            key: "cell(action)",
            fn: function (_ref8) {
              var index = _ref8.index,
                item = _ref8.item;
              return [_c('feather-icon', {
                staticClass: "text-danger cursor-pointer",
                attrs: {
                  "icon": "XCircleIcon",
                  "size": "24"
                },
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    return remove(index, item);
                  }
                }
              })];
            }
          }], null, true)
        })];
      }
    }])
  })], 1), _c('tab-content', {
    attrs: {
      "title": "Metode Pembayaran",
      "icon": "feather icon-credit-card"
    }
  }, [_c('penjualan-payment', {
    attrs: {
      "payload": _vm.payload
    },
    on: {
      "submit": _vm.submit
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }