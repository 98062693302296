var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Konsumen"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.konsumen,
      "label": "text"
    },
    scopedSlots: _vm._u([{
      key: "no-options",
      fn: function (_ref) {
        var search = _ref.search;
        return [_c('i', [_vm._v("Konsumen tidak ditemukan")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.addKonsumen(search);
            }
          }
        }, [_c('i', [_vm._v("Tambah")])])];
      }
    }]),
    model: {
      value: _vm.info.konsumen,
      callback: function ($$v) {
        _vm.$set(_vm.info, "konsumen", $$v);
      },
      expression: "info.konsumen"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Penjualan"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.info.tanggal,
      callback: function ($$v) {
        _vm.$set(_vm.info, "tanggal", $$v);
      },
      expression: "info.tanggal"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Daftar Barang"
    }
  }, [_c('b-button', {
    attrs: {
      "disabled": !_vm.info.konsumen,
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.searchBarang($event);
      }
    }
  }, [_vm._v("Cari Barang")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rute Penjualan"
    }
  }, [_c('v-select', {
    attrs: {
      "options": _vm.ruteOptions,
      "label": "text"
    },
    model: {
      value: _vm.info.rute,
      callback: function ($$v) {
        _vm.$set(_vm.info, "rute", $$v);
      },
      expression: "info.rute"
    }
  })], 1)], 1), _vm.selectedBarangs.length > 0 ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._t("content-selected-barang", null, {
    "barangs": _vm.selectedBarangs,
    "remove": _vm.remove,
    "doFormatRupiah": _vm.doFormatRupiah
  })], 2) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    staticClass: "d-flex justify-content-center justify-content-md-end",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticStyle: {
      "width": "25rem"
    },
    attrs: {
      "title": "Info"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('ul', {
    staticClass: "list-unstyled w-100"
  }, [_c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Konsumen ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.info.konsumen ? _vm.info.konsumen.text : '-'))])])]), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Tanggal Penjualan ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.info.tanggal))])])]), _c('li', {
    staticClass: "d-flex justify-content-between mb-1"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Jumlah Barang ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.selectedBarangs.length))])])]), _c('li', {
    staticClass: "d-flex justify-content-between mb-3"
  }, [_c('div', {
    staticClass: "details-title"
  }, [_vm._v(" Subtotal ")]), _c('div', {
    staticClass: "detail-amt"
  }, [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(_vm.subTotal)))])])]), _c('li', [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Metode Pembayaran ")])], 1)])])]), _c('stok-modal', {
    attrs: {
      "dataBarang": _vm.barangs
    },
    on: {
      "selected-barang": _vm.onSelectedBarang
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "konsumen-modal"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.storeKonsumen($event);
            }
          }
        }, [_vm._v("Tambah")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Owner"
    }
  }, [_c('b-form-input', {
    attrs: {
      "autofocus": ""
    },
    model: {
      value: _vm.konsumenPayload.nama_owner,
      callback: function ($$v) {
        _vm.$set(_vm.konsumenPayload, "nama_owner", $$v);
      },
      expression: "konsumenPayload.nama_owner"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Nama Toko"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.konsumenPayload.nama_toko,
      callback: function ($$v) {
        _vm.$set(_vm.konsumenPayload, "nama_toko", $$v);
      },
      expression: "konsumenPayload.nama_toko"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "No. Handphone"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.konsumenPayload.no_hp,
      callback: function ($$v) {
        _vm.$set(_vm.konsumenPayload, "no_hp", $$v);
      },
      expression: "konsumenPayload.no_hp"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }